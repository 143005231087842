import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"
import ModalVideo from "react-modal-video"

import Header from "../header/"
import LargeMenu from "../menu/largeMenu"
import Index from "../footer"
import "./layout.scss"
import { displayMenuAction, videoModalAction } from "../../store/actions";
import CookieNotice from '../CookieNotice';
import { capiPageView } from "../../services/capi"

const Layout = ({ children }) => {
    const dispatch = useDispatch()
    const hideVideoModal = () => {
        dispatch(videoModalAction({ display: false, vimeo: "71303723" }))
    }
    
    React.useEffect(
      ()=> {
        console.log("PAGEVIEW", window.location.href)
        capiPageView();
      },[]
    );

    const menu = useSelector(state => state.menu)
    const videoModal = useSelector(state => state.modal)

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    if (menu) {
        return (
            <>
                <LargeMenu />
            </>
        )
    }
    
    return (
        <>
            <CookieNotice />
            <Header />
            <main>{children}</main>
            <Index />

            {videoModal.display == true &&
            <div>
              <ModalVideo 
                  channel="vimeo"
                  isOpen={videoModal.display}
                  videoId={videoModal.vimeo}
                  hideVideoModal
                  onClose={hideVideoModal}
              />
            </div>
            }
        </>
    )
}

export default Layout
