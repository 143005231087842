import React, { Component } from "react";
import { useState } from "react";

import ReactTooltip from "react-tooltip";
import * as styles from  "./styles.module.scss";

const ToolTip = ({id,text,display}) => {

return(

      <div style={display ? {display:"block"} : {display:"none"}}>
                <ReactTooltip 
                id={id} 
                className={styles.customeTheme}
                place="bottom"
                effect="solid"
                >
                    <span> {text} </span>
                </ReactTooltip>
      </div>
)

}


export default ToolTip;