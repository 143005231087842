import React, { useEffect, useRef, useState } from "react"
import Link from "gatsby-link"

import { useDispatch } from "react-redux"

import { displayMenuAction } from "../../store/actions";

import * as styles from "./menu.module.scss"
import { GrClose } from "react-icons/all"
import { useStaticQuery, graphql } from "gatsby"


const LargeMenu = () => {

    const graphResult = useStaticQuery(graphql`
    {
        wpKurs(slug: {eq: "detox-me"}) {
          id
          title
          renderContent
        }
      }
  `)

    const [loggedIn, setLoggedIn] = useState(false);

    const dispatch = useDispatch()
    const largeMenuRef = useRef(null)
    useEffect(() => {

        largeMenuRef.current.classList.add(styles.active)
        setLoggedIn(localStorage.getItem('user') !== null);

    }, []);

    const closeMenu = () => {
        dispatch(displayMenuAction())
    }

    const courseData = JSON.parse(graphResult.wpKurs.renderContent);

    let countRows = 0;
    let menuRows = [];
    let elements = [];

    courseData.map((module, moduleKey) => {

        elements.push(<li style={{ paddingTop: 20 }}><h4>{module.headline}</h4></li>);

        module.lessons.forEach((lesson, i) => {

            if (lesson["type"] == "big") {

                elements.push(<li>
                    <Link to={loggedIn ? `/modules/${lesson["lesson_id"]}` : `/checkout`} onClick={closeMenu}>
                        {lesson.title}
                    </Link>
                </li>);

            } else if (lesson["id"] == "lesson_container") {

                lesson.lessons.forEach((smallLesson, i) => {
                    elements.push(<li>
                        <Link to={loggedIn ? `/modules/${smallLesson.lesson_id}` : `/checkout`} onClick={closeMenu}>
                            {smallLesson.title}
                        </Link>
                    </li>);
                })

            }

        })

        if (countRows === 3) {
            menuRows[parseInt(moduleKey / 4)] = elements;

            elements = [];
            countRows = 0;
        }

        countRows = countRows + 1;

    })

    return (
        <div className={styles.largeMenu} ref={largeMenuRef}>
            <div className={styles.lightVerticalLine}></div>
            <div className={styles.hightVerticalLine}></div>
            <div className={styles.container}>
                <button className={styles.closeButton} onClick={closeMenu}>
                    <GrClose />
                </button>
                <div className={styles.title}>Detox Kurs</div>
                <div className={styles.menuList}>

                    {menuRows.map(column => {
                        return <div>
                            <ul>
                                {
                                    column
                                }
                            </ul>
                        </div>
                    })}

                </div>
                <Link to="/contact" onClick={closeMenu} className={styles.contactItem}>
                    Kontakt
                </Link>
            </div>
        </div>
    )
}

export default LargeMenu
