// extracted by mini-css-extract-plugin
export var menu = "menu-module--menu--23x5F";
export var largeMenu = "menu-module--largeMenu--3zM0P";
export var active = "menu-module--active--2-JgZ";
export var lightVerticalLine = "menu-module--lightVerticalLine--kYEcX";
export var hightVerticalLine = "menu-module--hightVerticalLine--9zPNq";
export var title = "menu-module--title--1ILz2";
export var container = "menu-module--container--2OCWT";
export var closeButton = "menu-module--closeButton--2usZu";
export var menuList = "menu-module--menuList--3eKqZ";
export var contactItem = "menu-module--contactItem--3KLAw";