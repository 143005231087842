import axios from "axios";

const isLoggedIn = async () => {

    let userToken = localStorage.getItem("user");
    console.log(userToken)

    if (userToken) {

        let data = JSON.stringify({
            "token": userToken
        });

        var config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/video/progress',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                return true;
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });
    }

    else {
        return false;
    }

}

export default isLoggedIn;
