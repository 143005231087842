import React from "react"
import { Link } from "gatsby"

import * as styles from "./footer.module.scss"

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={`container ${styles.container}`}>
        <div> 
          Copyright 2021 detoxme! | Alle Rechte vorbehalten
        </div>
        <nav className={styles.nav}>
          <Link to="/privacy">
            <span style={{color:"white"}}>
              Datenschutz
            </span>
          </Link>
          <Link to="/imprint" style={{color:"white", marginLeft:"10px"}}>
            <span style={{color:"white"}}>
              Impressum
            </span>
          </Link>
        </nav>
        <a style={{color:"white !important"}} href="https://spectory.at" className="producer">
          <span style={{color:"white"}}>
            designed by spectory
          </span>
        </a>
      </div>
    </div>
  )
}

export default Footer
