import React, { Fragment, useEffect, useRef, useState } from "react"
import { Link, withPrefix } from "gatsby"
import { useDispatch } from "react-redux"
import ToolTip from "../tooltips/tooltips";

import { displayMenuAction } from "../../store/actions";

import * as styles from "./header.module.scss"

import Logo from "./detox_logo_original_unterschrift.svg"
import ShoppingCartIcon from "./icon_shoppingbasket.svg"
import MenuIcon from "./icon_menu.svg"
import isLoggedIn from "../../services/auth";
import { navigate } from "@reach/router";
import LogoutIcon from './logout.svg';
import VideoIcon from './video.svg';
import clearLocalStorage from "../../services/clearLocalStorage";


const Header = () => {

    const [loggedIn, setLoggedIn] = useState(false);

    const dispatch = useDispatch()
    const openMenu = (e) => {
        dispatch(displayMenuAction())
    }
    const headerRef = useRef(null)
  
    useEffect(() => {

        headerRef.current.classList.add(styles.active);

        setLoggedIn(localStorage.getItem('user') !== null);

    }, [])
    return (
        <div ref={headerRef} className={styles.header}>
            <div className={`container ${styles.container}`}>
                <Link to="/">
                    <Logo className={styles.logo} />
                </Link>
                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <Link to={loggedIn ? '/modules' : '/login'} data-for="login-modules" data-tip="login modules">
                                {loggedIn ? 
                                    <VideoIcon className={styles.videoIcon} /> 
                                : 'Log in'}
                            </Link>
                                <ToolTip 
                                display ={loggedIn ? true : false}
                                id="login-modules"
                                text={loggedIn ? 'Zu deinen Einheiten' : 'Login'}
                                />           
                        </li>
                        {
                            loggedIn && <li>
                                <div style={{ cursor: 'pointer' }} 
                                data-for="logout" 
                                data-tip="logout"
                                onClick={() => {
                                    clearLocalStorage();
                                    navigate(withPrefix('/'));
                                    window.location.reload();
                                }}>
                                    <LogoutIcon className={styles.logoutIcon} />
                                </div>
                                <ToolTip 
                                display={true}
                                id="logout"
                                text="Logout"
                                />
                            </li>
                        }
                        {
                            !loggedIn && <li>
                                <Link to="/checkout">
                                    <ShoppingCartIcon className={styles.icon} 
                                    data-for="shopping-cart" data-tip="shopping cart" />
                                    <ToolTip 
                                        display={true}
                                        id="shopping-cart"
                                        text="Kurs kaufen"
                                    />
                                </Link>

                            </li>
                        }
                        <li>
                            <button onClick={openMenu}>
                                <MenuIcon className={styles.icon} />
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header
