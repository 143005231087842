import axios from "axios";
import { API_BASE } from "../spectory-config";

const capi = (event, data = {}, userData = {}) => {

    let config = {
        method: 'post',
        url: `${API_BASE}/capi`,
        headers: {
            // 'Content-Type': 'application/json'
        },
        data: {
            event: event,
            user: userData,
            custom: data
        }
    };

    axios(config)
        .then((response) => {
        }
        )
        .catch(function (error) {
        });

}
export default capi;


export const capiPageView = () => {
    capi('ViewContent', {
        'url': window.location.href
    });
}

export const capiStartPurchase = () => {
    capi('PURCHASE');
}
